
import { getBoxValue } from './Const/functions';
import { getMultiShadowCSS } from '../../bpl-tools/utils/getCSS'

const Style = ({ attributes, clientId }) => {
    const { alignment, frameBorder, frameBRadius, frameBoxShadow, iframeStyle } = attributes;
    const { width, height } = iframeStyle;

    return <style dangerouslySetInnerHTML={{
        __html: `
        .wp-block-scb-sound-cloud {
            text-align:${alignment};
        }
        #scbSoundCloud-${clientId} .mainWidget{
            width:${width};
            height:${height};
        }
		#scbSoundCloud-${clientId} iframe{
            border: ${getBoxValue(frameBorder)};
            border-radius: ${getBoxValue(frameBRadius)};
            box-shadow: ${getMultiShadowCSS(frameBoxShadow?.shadow || frameBoxShadow)};
        }
        `.replace(/\s+/g, ' ')
    }} />
}
export default Style;